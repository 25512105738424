import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, forEach } from 'lodash';
import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import Link from 'components/Link';
import Icon from 'components/ContentfulFields/Icon/Loadable';
import { showDrawer } from 'containers/DrawerManager/actions';
import { getRegionalBrandName } from 'utils/domainHelper';
import { translateData, getToUrl } from 'utils/translation';

const Button = (
  {
    document: {
      fields: {
        type,
        linkType,
        icon,
        size,
        color,
        iconImage,
        brandedIconImages,
        openDrawer,
        ...fields
      },
    },
    text,
    className,
    onClick,
    loading,
    disabled,
    link,
    useImageSize,
    id,
    tabIndex = null,
    to = '',
  },
  { stylesTheme },
) => {
  text = text || fields?.text;

  if (!text && !icon && !iconImage) return null;

  const translatedText = translateData(text, 'C42');
  let theIconImage = iconImage;
  const regionalBrandName = getRegionalBrandName(null, 4);

  if (brandedIconImages) {
    forEach(brandedIconImages, (image) => {
      const fieldsTitle = get(image, 'fields.title');
      if (fieldsTitle.includes(regionalBrandName)) {
        theIconImage = image;
      }
    });
  }

  const styles = require(`./styles/${stylesTheme}.scss`);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (openDrawer) {
      dispatch(showDrawer(openDrawer));
    }

    if (onClick) {
      onClick();
    }
  };

  const buttonStyle = classNames(styles.btn, className, {
    [styles.icon]: !translatedText,
    [styles.link]: link,
    [styles[type]]: type && styles[type],
    [styles.disabled]: disabled,
    [styles[size]]: size,
    [styles[color]]: color,
    [styles.iconImage]: theIconImage,
  });

  const imageSizing = useImageSize
    ? { ...theIconImage.fields.file.details.image }
    : undefined;

  const doClick = fields.link
    ? fields.link.includes('http')
      ? () => {
          window.open(fields.link);
          handleClick();
        }
      : () => {
          dispatch(push(getToUrl(fields.link, 'U1')));
          handleClick();
        }
    : handleClick;

  const labelledby = id ? `cat_description_${id}` : translatedText;

  const isAnchorLink = to || linkType === 'Anchor';

  return (
    <div className={buttonStyle} id={labelledby}>
      {isAnchorLink ? (
        <Link to={to || fields.link} onClick={onClick}>
          {!theIconImage && translatedText}
          {icon && <Icon document={icon} />}
          {theIconImage && (
            <img
              src={`${theIconImage.fields.file.url}?fm=webp`}
              alt={''}
              style={imageSizing}
            />
          )}
        </Link>
      ) : (
        <MUIButton
          onClick={doClick}
          id={id}
          aria-labelledby={labelledby}
          type="submit"
          disableRipple
          aria-current="page"
          tabIndex={tabIndex}
        >
          {!theIconImage && translatedText}
          {icon && <Icon document={icon} />}
          {theIconImage && (
            <img
              src={`${theIconImage.fields.file.url}?fm=webp`}
              alt={''}
              style={imageSizing}
            />
          )}
        </MUIButton>
      )}
      {loading && (
        <CircularProgress size={24} className={styles.buttonProgress} />
      )}
      {theIconImage && (
        <div id={labelledby} className={styles.text}>
          {translatedText}
        </div>
      )}
    </div>
  );
};

Button.propTypes = {
  document: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.bool,
  useImageSize: PropTypes.bool,
};

Button.defaultProps = {
  document: { fields: {} },
  onClick: () => {},
  loading: false,
  disabled: false,
  link: false,
  useImageSize: false,
};

Button.contextTypes = {
  stylesTheme: PropTypes.string,
};

export default Button;
