import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import { useMediaQuery } from '@material-ui/core';
import {
  Counter,
  Icon,
  IconButton,
  Button,
  H2,
  Div,
  Span,
} from 'components/Ui';
import { CloseIconSvg } from 'components/Ui/CustomIcons';
import ScreenReader, {
  HiddenContent,
  HiddenContentSpan,
} from 'components/Ui/ScreenReader';
import MessageDialog from 'components/Dialogs/MessageDialog';
import { formatCurrency, getProductName } from 'utils/common';
import { dataLayerPush } from 'utils/tracking';
import { getImage } from 'images';
import { useTheme } from '@material-ui/core/styles';
import { isSsr } from 'utils/ssrHelper';
import { isEquipment } from 'utils/categories';
import { translateData, getCurrencyCode } from 'utils/translation';
import styles from './styles.scss';

const ProductListItem = ({
  product,
  onAddToRecurringOrder,
  onQuantityChange,
  onDelete,
  inDrawer,
  usePropsCountValue = false,
  checkoutView = false,
  cartView = false,
  yourOrderView = false,
  bottom,
  disableDelete,
  ...props
}) => {
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [isCostco, setIsCostco] = useState(false);
  const [quantityContent, updateQuantityContent] = useState(null);

  const editable = Boolean(onQuantityChange);
  const theme = useTheme();
  const productPrice = get(product, 'price', 0);
  let quantity = get(product, 'quantity', 1);
  if (product.deliveryFrequency === 'Replenish') {
    const productAverageQuantity = get(product, 'itemAverageQuantity', null);
    quantity = productAverageQuantity || quantity;
  }
  const handleQuantityChange = (newCount) => {
    const verb = newCount > quantity ? 'added' : 'removed';
    updateQuantityContent(
      `${getProductName(product)} ${translateData(verb)}, ${translateData(
        'new quantity is',
      )} ${newCount}`,
    );
    onQuantityChange(product, newCount, props.index);
  };

  const handleIncrement = () => {
    if (cartView) {
      // mmm aaa handleIncrement
      dataLayerPush(
        'Acquisition',
        {
          event: 'addToCart',
          ecommerce: {
            currencyCode: getCurrencyCode(),
            add: {
              products: [
                {
                  name: product.name,
                  id: product.itemNumber,
                  price: `${product.price}`,
                  brand: product.brand || '',
                  category: `${product.category}${
                    product.isBestSeller ? ' | Best Seller' : ''
                  }`,
                  variant: product.size,
                  quantity: 1,
                },
              ],
            },
          },
        },
        'dlA7',
        'PUSH_ADD_TO_CART_DATA',
      );
    }
  };

  const handleDecrement = () => {
    if (cartView) {
      // mmm rrr handleDecrement
      dataLayerPush(
        'Acquisition',
        {
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: product.name,
                  id: product.itemNumber,
                  brand: product.brand || '',
                  price: `${product.price}`,
                  category: product.category,
                  variant: product.size,
                  quantity: 1,
                },
              ],
            },
          },
        },
        'dlA8',
        'PUSH_REMOVE_FROM_CART_DATA',
      );
    }
  };

  const handleRemove = () => {
    if (disableDelete) {
      setOpenMessageDialog(true);
    } else {
      onDelete(product, props.index);
    }
  };

  const image = getImage(product, 'images[0].fields.file.url', 'image.url');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isSsr()) {
      setIsCostco(window.isCostcoWater);
    }
  }, []);

  const deleteButtonColor = disableDelete
    ? theme.colors.extraLightGrey
    : theme.colors.heavySome;

  const cannotDeleteTitle = 'Oops!';
  const cannotDeleteMessage =
    'Looks like you’re trying to remove all the items from this delivery. If you need to skip this delivery, click on the skip delivery button instead.';

  const quantityComp = (
    <Fragment>
      <Span>Quantity</Span>
      <ScreenReader>
        {translateData('Current quantity')}: {quantity}
      </ScreenReader>
      <HiddenContent aria-live="polite" aria-atomic="true">
        {quantityContent && <span>{quantityContent}</span>}
      </HiddenContent>
      {editable ? (
        <Counter
          value={quantity}
          className={styles.counter}
          productTitle={getProductName(product)}
          onChange={handleQuantityChange}
          usePropsValue={usePropsCountValue}
          minValue={1}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
      ) : (
        <span className={styles.quantityLabel}>{quantity}</span>
      )}
    </Fragment>
  );

  const unitPriceComp = (
    <div className={styles.total}>
      <HiddenContentSpan className={styles.hiddenContent}>
        {translateData('Unit Price')}
      </HiddenContentSpan>
      <Span className={styles.totalLabel}>Unit Price</Span>
      {/* TODO: Remove this calculation when API "total" property will be implemented */}
      <span>{formatCurrency(productPrice)}</span>
    </div>
  );

  const totalComp = (
    <div role="status" className={styles.total}>
      <HiddenContentSpan className={styles.hiddenContent}>
        {translateData('Total')}
      </HiddenContentSpan>
      <Span className={styles.totalLabel}>Total</Span>
      {/* TODO: Remove this calculation when API "total" property will be implemented */}
      <span>{formatCurrency(productPrice * quantity)}</span>
    </div>
  );

  const totalsComp = (
    <div
      className={classnames(styles.totals, {
        [styles.totalsInDrawer]: inDrawer,
      })}
    >
      {unitPriceComp}
      {totalComp}
    </div>
  );

  const totalsElements = inDrawer ? (
    <Fragment>{totalsComp}</Fragment>
  ) : (
    <Fragment>
      {((!cartView && checkoutView) || yourOrderView || inDrawer) &&
        unitPriceComp}
      {!cartView && totalComp}
    </Fragment>
  );

  const fontSize = isMobile && inDrawer && bottom ? 'large' : 'small';
  const deleteButton = onDelete &&
    product &&
    (product.removable || props.removable) && (
      <IconButton
        className={classnames(styles.deleteIcon, {
          [styles.yourOrder]: yourOrderView,
        })}
        onClick={handleRemove}
      >
        <HiddenContentSpan>{`${translateData('Remove all')} ${getProductName(
          product,
        )} ${translateData('from')} ${
          yourOrderView ? translateData('next delivery') : translateData('cart')
        }.`}</HiddenContentSpan>
        <CloseIconSvg
          id={product.itemNumber}
          aria-label={`${translateData('Remove')} ${getProductName(product)}`}
          data-tracking={`Remove ${getProductName(product)}`}
          fontSize={fontSize}
          color={deleteButtonColor}
          hoverColor={deleteButtonColor}
        />
      </IconButton>
    );

  return (
    <div
      className={styles.itemWrapper}
      data-tracking={`Product - ${getProductName(product)}`}
    >
      <div
        className={classnames(styles.order, props.className, {
          [styles.drawerProduct]: inDrawer && !isMobile,
        })}
      >
        <div className={styles.productInfo}>
          <div className={styles.image}>
            {image && <img src={`${image}?fm=webp`} alt="" />}
          </div>
          <div className={classnames(styles.description, styles.productInfo)}>
            <Fragment>
              <ScreenReader>Product in cart:</ScreenReader>
              <H2
                className={classnames({ [styles.yourOrder]: yourOrderView })}
                defaultText={'C60'}
              >
                {getProductName(product)}
              </H2>
            </Fragment>
            {isCostco ? (
              <div className={styles.info}>
                {isEquipment(product.category) ? (
                  <Fragment>
                    <br />
                  </Fragment>
                ) : (
                  <Fragment>
                    {product.size && (
                      <Fragment>
                        <ScreenReader>
                          {translateData('Product size')}:
                        </ScreenReader>
                        {translateData(product.size, 'C0')}
                        <br />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            ) : (
              <div className={styles.info}>
                {product.size && (
                  <Fragment>
                    <ScreenReader>
                      {translateData('Product size')}:
                    </ScreenReader>
                    {translateData(product.size, '0')}
                    <br />
                  </Fragment>
                )}
                {product.count && translateData(product.count, 'C0')}
              </div>
            )}

            {!product.isRecurring && (
              <div className={styles.actions}>
                {editable && onAddToRecurringOrder && (
                  <Button
                    className={styles.editButton}
                    onClick={() => onAddToRecurringOrder(product)}
                    text="Add to recurring order"
                    aria-label="Add to recurring order"
                    link
                  />
                )}
              </div>
            )}
            {checkoutView && (
              <div className={styles.type}>
                {isEquipment(product.category) ? (
                  <Fragment>
                    <Icon icon="home" />
                    <Div>This is your rental equipment</Div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Icon icon="infinity" />
                    <Div>This is a recurring purchase</Div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={classnames(styles.orderInfo, {
            [styles.wide]: editable,
            [styles.superWide]: yourOrderView,
            [styles.cartView]: cartView,
            [styles.drawerView]: inDrawer,
          })}
        >
          <div className={styles.quantity}>
            {quantityComp}
            {cartView && checkoutView && !yourOrderView && totalsComp}
          </div>
          {totalsElements}
        </div>

        {isMobile && (yourOrderView || inDrawer) && product.specialNotes && (
          <Div
            className={classnames(styles.specialNotes, {
              [styles.drawerSpecialNotes]: inDrawer,
            })}
          >
            <Icon icon="info-circle" />
            {product.specialNotes}
          </Div>
        )}

        {!(bottom && isMobile) && deleteButton}
      </div>
      {!isMobile && product.specialNotes && (
        <Div
          className={classnames(styles.specialNotes, {
            [styles.drawerSpecialNotes]: inDrawer,
          })}
        >
          <Icon icon="info-circle" />
          {product.specialNotes}
        </Div>
      )}
      {bottom && <div className={styles.bottom}>{bottom}</div>}
      {bottom && isMobile && (
        <div className={styles.closeIconContainer}>{deleteButton}</div>
      )}

      {openMessageDialog && (
        <MessageDialog
          open={openMessageDialog}
          onClose={() => setOpenMessageDialog(false)}
          title={cannotDeleteTitle}
          message={cannotDeleteMessage}
        />
      )}
    </div>
  );
};

ProductListItem.propTypes = {
  product: PropTypes.object,
  onAddToRecurringOrder: PropTypes.func,
  onDelete: PropTypes.func,
  onQuantityChange: PropTypes.func,
  inDrawer: PropTypes.bool,
  usePropsCountValue: PropTypes.bool,
  className: PropTypes.string,
  removable: PropTypes.bool,
  checkoutView: PropTypes.bool,
  cartView: PropTypes.bool,
  yourOrderView: PropTypes.bool,
  index: PropTypes.number,
  bottom: PropTypes.element,
  disableDelete: PropTypes.bool,
};

ProductListItem.defaultProps = {
  className: null,
  removable: false,
  disableDelete: false,
};

export default ProductListItem;
