import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Button, H5, Span, Section } from 'components/Ui';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CreditCardForm from 'containers/PrimoCheckoutPage/Payment/CreditCardForm';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  BILLING_TYPE_SHIPPING,
  PAYMENT_PAGE,
} from 'containers/PrimoCheckoutPage/constants';
import { placeOrder } from 'containers/CheckoutPage/actions';
import { requiredCardFields } from 'containers/PrimoCheckoutPage/utils';
import {
  selectCheckoutLoading,
  selectCheckoutPaymentErrors,
} from 'containers/CheckoutPage/selectors';
import {
  selectDeliverySetupData,
  selectContactData,
  selectDeliveryData,
  selectBillingData,
  selectPaymentData,
} from 'containers/Cart/selectors';
import commonStyles from 'containers/PrimoCheckoutPage/styles.scss';
import creditCardStyles from 'containers/PrimoCheckoutPage/Payment/styles.scss';
import { useIsMobile } from 'utils/hooks';
import { dataLayerPush } from 'utils/tracking';
import { getToUrl } from 'utils/translation';
import DeliveryInformationDrawer from 'containers/PrimoCheckoutPage/DeliveryInfomation/DeliveryInformationDrawer';
import PaymentDrawer from 'containers/PrimoCheckoutPage/Payment/PaymentDrawer';
import styles from './styles.scss';

const ScreenReader = styled.p`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Review = ({ changeCanGoForward, changeSubmitAction }) => {
  const dispatch = useDispatch();
  const form = useRef();
  const isMobile = useIsMobile();
  const contactData = useSelector(selectContactData());
  const deliveryData = useSelector(selectDeliveryData());
  const deliveryInformation = { ...contactData, ...deliveryData };
  const deliverySetup = useSelector(selectDeliverySetupData());
  const billingData = useSelector(selectBillingData());
  const paymentInfo =
    billingData.billingType === BILLING_TYPE_SHIPPING
      ? deliveryData
      : billingData;
  const creditCard = useSelector(selectPaymentData());
  const showCreditCardForm = useMemo(() => !creditCard.last4, [
    creditCard.last4,
  ]);
  const isProcessing = useSelector(selectCheckoutLoading());
  const hasPaymentErrors = useSelector(selectCheckoutPaymentErrors());
  const [values, changeValues] = useState({});
  const [showDeliverySidebar, changeShowDeliverySidebar] = useState(false);
  const toggleEditDelivery = () =>
    changeShowDeliverySidebar(!showDeliverySidebar);
  const [showPaymentSidebar, changeShowPaymentSidebar] = useState(false);
  const toggleEditPayment = () => changeShowPaymentSidebar(!showPaymentSidebar);
  const [
    showValidatePaymentSidebar,
    changeShowValidatePaymentSidebar,
  ] = useState(false);
  const toggleValidatePayment = () =>
    changeShowValidatePaymentSidebar(!showValidatePaymentSidebar);
  const handleChange = ({ target: { name, value } }) => {
    changeValues({ ...values, [name]: value });
  };
  const [captchaSiteKey, setCaptchaSiteKey] = useState();
  const [captchaError, setCaptchaError] = useState(true);
  const reCaptchaRef = useRef();

  const isCreditCardFilled =
    !showCreditCardForm || requiredCardFields.every((field) => values[field]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = reCaptchaRef.current.getValue();
    if (token && !captchaError) {
      submitOrder(token);
    }

    reCaptchaRef.current.reset();
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaError(false);
    } else {
      setCaptchaError(true);
    }
  };

  const submitOrder = (token) => {
    const requestData = { deliverySetup };

    if (!creditCard.last4) {
      requestData.creditCardData = values;
    }

    dispatch(placeOrder({ ...requestData, captchaToken: token }));
    changeShowValidatePaymentSidebar(true);
  };

  const handleBack = () =>
    dispatch(push(getToUrl(`/checkout/${PAYMENT_PAGE}`, 'U51')));

  useEffect(() => {
    setCaptchaSiteKey(window.SERVER_DATA.RECAPTCHA2_SITE_KEY);
    const creditCardType = creditCard.cardType;

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkoutOption',
        ecommerce: {
          checkout_option: {
            actionField: { step: '3', option: `${creditCardType}` },
          },
        },
      },
      'dlA18',
    );

    dataLayerPush(
      'Acquisition',
      {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: { step: 4 },
          },
        },
      },
      'dlA20',
    );
  }, []);

  useEffect(() => {
    changeSubmitAction(() => () => form.current.submit());
  }, []);

  useEffect(() => {
    changeCanGoForward(isCreditCardFilled);
  }, [isCreditCardFilled]);

  const deliveryInformationAddress2 = deliveryInformation.address2
    ? `, ${deliveryInformation.address2}`
    : '';
  const paymentInfoAddress2 = paymentInfo.address2
    ? `, ${paymentInfo.address2}`
    : '';

  return (
    <Section
      aria-label="Review delivery and payment information"
      id="reviewDeliveryInformation"
    >
      {captchaSiteKey && (
        <ValidatorForm onSubmit={handleSubmit} ref={form}>
          {showCreditCardForm && (
            <div
              className={classnames(
                creditCardStyles.block,
                styles.creditCardContainer,
              )}
            >
              <CreditCardForm values={values} handleChange={handleChange} />
            </div>
          )}
          <Grid container spacing={isMobile ? 1 : 4}>
            <Grid item md={8} sm={12}>
              <div className={classnames(styles.block, styles.blockFlex)}>
                <div>
                  <H5 className={classnames(commonStyles.title, styles.title)}>
                    Your Delivery Info:
                  </H5>
                </div>
                <div>
                  <Button
                    onClick={toggleEditDelivery}
                    ariaLabel="Your delivery information"
                    className={commonStyles.editLink}
                    link
                    text="Edit"
                  />
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.infoItem}>
                  <H5>Name:</H5>
                  <p>
                    {deliveryInformation.firstName}{' '}
                    {deliveryInformation.lastName}
                  </p>
                </div>
                <div className={styles.infoItem}>
                  <H5>Phone:</H5>
                  <p>{deliveryInformation.phoneNumber}</p>
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.infoItem}>
                  <H5>Delivery Address:</H5>
                  <p>
                    {deliveryInformation.address1}
                    {deliveryInformationAddress2}, {deliveryInformation.city},{' '}
                    {deliveryInformation.stateOrProvinceCode}{' '}
                    {deliveryInformation.postalCode}
                  </p>
                </div>
                <div className={styles.infoItem}>
                  <H5>Email:</H5>
                  <p>{deliveryInformation.email}</p>
                </div>
              </div>
            </Grid>
            <Grid item md={4} sm={12}>
              <div className={classnames(styles.block, styles.blockFlex)}>
                <div>
                  <H5 className={classnames(commonStyles.title, styles.title)}>
                    Your Payment Info:
                  </H5>
                </div>
                <div>
                  <Button
                    onClick={toggleEditPayment}
                    className={commonStyles.editLink}
                    ariaLabel="Your payment information"
                    link
                    text="Edit"
                  />
                </div>
              </div>
              {!showCreditCardForm && (
                <div className={styles.infoBlock}>
                  <div className={styles.fullInfoItem}>
                    <H5>Method:</H5>
                    <p>
                      <Span>Credit Card ending in</Span> {creditCard.last4}
                    </p>
                  </div>
                </div>
              )}
              <div className={styles.infoBlock}>
                <div className={classnames(styles.fullInfoItem)}>
                  <H5>Billing Address:</H5>
                  <p>
                    {paymentInfo.address1}
                    {paymentInfoAddress2}, {paymentInfo.city},{' '}
                    {paymentInfo.stateOrProvinceCode} {paymentInfo.postalCode}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <hr />
          <div className={classnames(commonStyles.actions, styles.actions)}>
            <ScreenReader>
              <Span>
                Form Navigation, Select the Back button to return to the Payment
                form. Select continue to the Complete Order form.
              </Span>
            </ScreenReader>
            <Button
              text="Back"
              color="gray"
              type="button"
              onClick={handleBack}
              disabled={isProcessing}
            />
            <div className={styles.captchaContainer}>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={captchaSiteKey}
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              text="Complete order"
              disabled={!isCreditCardFilled || captchaError}
              loading={isProcessing}
            />
          </div>
        </ValidatorForm>
      )}
      <DeliveryInformationDrawer
        open={showDeliverySidebar}
        onConfirm={() => { }}
        onCancel={() => { }}
        toggle={toggleEditDelivery}
        values={deliveryInformation}
        isDrawer="true"
      />
      <PaymentDrawer
        open={showPaymentSidebar}
        onConfirm={() => { }}
        onCancel={() => { }}
        toggle={toggleEditPayment}
        values={paymentInfo}
        isValidPayment
      />
      <PaymentDrawer
        open={showValidatePaymentSidebar && hasPaymentErrors}
        onConfirm={() => { }}
        onCancel={() => { }}
        toggle={toggleValidatePayment}
        values={paymentInfo}
        isValidPayment={false}
      />
    </Section>
  );
};

Review.propTypes = {
  changeCanGoForward: PropTypes.func.isRequired,
  changeSubmitAction: PropTypes.func.isRequired,
};

export default Review;
