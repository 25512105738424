import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CREDIT_CARD_FORM,
  BILLING_ADDRESS_FORM,
} from 'containers/CheckoutPage/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckoutErrors,
  selectCheckoutLoading,
} from 'containers/CheckoutPage/selectors';
import { savePayment } from 'containers/CheckoutPage/actions';
import {
  selectPaymentData,
  selectBillingData,
} from 'containers/Cart/selectors';
import { getToUrl } from 'utils/translation';
import { push } from 'connected-react-router';
import { SETUP_PAGE } from 'containers/PrimoCheckoutPage/constants';
import PaymentForm from './PaymentForm';
import PaymentFormOld from './PaymentFormOld';
import ValidatePaymentForm from './ValidatePaymentForm';

const Payment = ({
  changeCanGoForward,
  changeSubmitAction,
  drawerToggle,
  isDrawer,
  isValidPayment = true,
}) => {
  const dispatch = useDispatch();
  const form = useRef();

  const isProcessing = useSelector(selectCheckoutLoading());
  const errors = useSelector(selectCheckoutErrors()) || {};
  const paymentData = useSelector(selectPaymentData());
  const billingData = useSelector(selectBillingData());

  useEffect(() => {
    changeSubmitAction(() => () => form.current.submit());
  }, []);

  const handleSubmit = ({ values, isSaveCard }) => {
    const formData = {
      drawerToggle,
      [BILLING_ADDRESS_FORM]: {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        stateOrProvinceCode: values.stateOrProvinceCode,
        postalCode: values.postalCode,
        countryCode: values.countryCode,
        billingType: values.billingType,
      },
    };

    if (isSaveCard) {
      formData[CREDIT_CARD_FORM] = {
        cardholderName: values.cardholderName,
        cardNumber: values.cardNumber,
        expirationMonth: values.expirationMonth,
        expirationYear: values.expirationYear,
        statementDeliveryMethod: values.statementDeliveryMethod,
        cvv: values.cvv,
      };
    }

    dispatch(savePayment({ ...formData, drawerToggle, isDrawer }));
  };

  const handleBack = isDrawer
    ? () => drawerToggle()
    : () => dispatch(push(getToUrl(`/checkout/${SETUP_PAGE}`, 'U50')));

  return isValidPayment ? (
    <>
      <div
        id="oldComponent"
        data-tracking="payment old component"
        style={{ display: 'none' }}
      >
        <PaymentFormOld
          forwardedRef={form}
          onSubmit={handleSubmit}
          isProcessing={isProcessing}
          storedValues={{ ...paymentData, ...billingData }}
          changeCanGoForward={changeCanGoForward}
          errors={{
            ...errors[CREDIT_CARD_FORM],
            ...errors[BILLING_ADDRESS_FORM],
          }}
          isDrawer={isDrawer}
          onBack={handleBack}
        />
      </div>
      <div id="newComponent" data-tracking="payment new component">
        <PaymentForm
          forwardedRef={form}
          onSubmit={handleSubmit}
          isProcessing={isProcessing}
          storedValues={{ ...paymentData, ...billingData }}
          changeCanGoForward={changeCanGoForward}
          errors={{
            ...errors[CREDIT_CARD_FORM],
            ...errors[BILLING_ADDRESS_FORM],
          }}
          isDrawer={isDrawer}
          onBack={handleBack}
        />
      </div>
    </>
  ) : (
    <ValidatePaymentForm
      forwardedRef={form}
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      storedValues={{ ...paymentData, ...billingData }}
      changeCanGoForward={changeCanGoForward}
      errors={{ ...errors[CREDIT_CARD_FORM], ...errors[BILLING_ADDRESS_FORM] }}
      isDrawer={isDrawer}
      onBack={handleBack}
    />
  );
};

Payment.propTypes = {
  changeCanGoForward: PropTypes.func,
  changeSubmitAction: PropTypes.func.isRequired,
  drawerToggle: PropTypes.func,
  isDrawer: PropTypes.bool,
  isValidPayment: PropTypes.bool,
};

export default Payment;
