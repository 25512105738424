import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Div, Input, P, Select, Dialog, H2, H3, Img } from 'components/Ui';
import { creditCard4444, creditCard465 } from 'components/Ui/Input/masks';
import {
  CREDIT_EXPIRATION_MONTHS,
  CREDIT_EXPIRATION_MONTHS_CURRENT,
  CREDIT_EXPIRATION_YEARS,
} from 'utils/selectOptions';
import { lockIcon, InfoIcon } from 'images';
import { useIsMobile, useExpirationValidation } from 'utils/hooks';
import ccBack from 'images/ccBack.png';
import ccFront from 'images/ccFront.png';

import styles from './styles.scss';

const CreditCardForm = ({
  values,
  handleChange,
  isDrawer,
  showAsValidateError,
}) => {
  const [expirationMonthValue, isCurrentYearPicked] = useExpirationValidation(
    values,
  );
  const isMobile = useIsMobile();
  const gridSpacing = useMemo(() => (isMobile ? 1 : 4), [isMobile]);
  const [showCvvInfo, setShowCvvInfo] = useState(false);
  const cardholderNameProps = showAsValidateError
    ? {
        error: true,
        label: <span className={styles.validateLabel}>Name on Card:</span>,
        validators: ['required'],
        errorMessages: [''],
      }
    : {
        label: 'Name on Card:',
        validators: ['required'],
        errorMessages: [''],
      };

  const cardNumberProps = showAsValidateError
    ? {
        error: true,
        label: <span className={styles.validateLabel}>Card Number:</span>,
        validators: ['required', 'creditCard', 'creditCardType'],
        errorMessages: ['', 'Invalid card number', 'Card type not accepted'],
      }
    : {
        label: 'Card Number:',
        validators: ['required', 'creditCard', 'creditCardType'],
        errorMessages: ['', 'Invalid card number', 'Card type not accepted'],
      };
  const cvvProps = showAsValidateError
    ? {
        error: true,
        label: <span className={styles.validateLabel}>CVV:</span>,
        validators: ['required', 'number', 'minStringLength:3'],
        errorMessages: ['CVV required', 'Invalid CVV', 'Invalid CVV'],
      }
    : {
        label: 'CVV:',
        validators: ['required', 'number', 'minStringLength:3'],
        errorMessages: ['CVV required', 'Invalid CVV', 'Invalid CVV'],
      };
  const creditCardMask =
    values.cardNumber && values.cardNumber.startsWith('3')
      ? creditCard465
      : creditCard4444;

  return (
    <Fragment>
      <Grid {...(!isDrawer ? { container: true } : {})} spacing={gridSpacing}>
        <Grid item sm={12} md={isDrawer ? 12 : 6}>
          <Input
            {...cardholderNameProps}
            name="cardholderName"
            value={values.cardholderName}
            onChange={handleChange}
            ariaLabel="Name on card"
            autocomplete="billing cc-name"
            id="credit-card-name"
          />
        </Grid>
      </Grid>
      <Grid container={!isDrawer} spacing={gridSpacing}>
        <Grid item sm={12} md={isDrawer ? 12 : 6}>
          <Input
            {...cardNumberProps}
            value={values.cardNumber}
            onChange={handleChange}
            name="cardNumber"
            mask={creditCardMask}
            ariaLabel="Credit Card Number"
            autocomplete="billing cc-number"
            id="credit-card-number"
          />
        </Grid>
        <Grid item sm={12} md={isDrawer ? 12 : 6}>
          <Grid
            container
            spacing={
              2
            } /* container={isDrawer} {...(isDrawer ? {} : { spacing: 2 }) } */
          >
            <Grid item sm={6} lg={6}>
              <Select
                label="Expiration Month:"
                error={showAsValidateError}
                placeholder="Month"
                items={
                  isCurrentYearPicked
                    ? CREDIT_EXPIRATION_MONTHS_CURRENT
                    : CREDIT_EXPIRATION_MONTHS
                }
                validators={['required', 'expMonth']}
                errorMessages={['', '']}
                value={expirationMonthValue}
                icon="primaryArrow"
                onChange={handleChange}
                name="expirationMonth"
                ariaLabel="Credit Card Expiration Month"
                autocomplete="billing cc-exp-month"
                id="credit-card-exp-month"
              />
            </Grid>
            <Grid item sm={6} lg={6}>
              <Select
                label="Expiration Year:"
                error={showAsValidateError}
                placeholder="Year"
                items={CREDIT_EXPIRATION_YEARS}
                validators={['required']}
                errorMessages={['']}
                value={values.expirationYear}
                onChange={handleChange}
                icon="primaryArrow"
                name="expirationYear"
                ariaLabel="Credit Card Expiration Year"
                autocomplete="billing cc-exp-year"
                id="credit-card-exp-year"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Input
                label="CVV:"
                value={values.cvv}
                onChange={handleChange}
                name="cvv"
                id="cvv"
                maxLength={4}
                type="password"
                {...cvvProps}
              />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Div
                onClick={() => setShowCvvInfo(true)}
                className={styles.cvvInfo}
              >
                cvvInfo
              </Div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <P className={styles.ccFeeDisclaimer}>CC fee/surcharge disclaimer</P>
      <div className={styles.note}>
        <img src={lockIcon} alt="" />
        <Div>Your account information is safe and secure.</Div>
      </div>
      <div className={styles.cvvDescription} id="cvv-description">
        <Dialog
          size="small"
          onClose={() => setShowCvvInfo(false)}
          open={showCvvInfo}
          fullScreen={isMobile}
          aria-labelledby="Learn More CVV"
        >
          <div className={styles.modal}>
            <div className={styles.header}>
              <img src={InfoIcon} alt="" />
              <H2>cvvDialogTitle</H2>
            </div>
            <Grid container spacing={2}>
              <Grid item className={styles.cardSection}>
                <H3>cvvVisaTitle</H3>
                <Img src={ccBack} className={styles.ccImage} />
                <Div className={styles.text}>cvvVisaDescription</Div>
              </Grid>
              <Grid item className={styles.cardSection}>
                <H3>cvvAmexTitle</H3>
                <Img src={ccFront} className={styles.ccImage} />
                <Div className={styles.text}>cvvAmexDescription</Div>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    </Fragment>
  );
};

CreditCardForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDrawer: PropTypes.bool,
  showAsValidateError: PropTypes.bool,
};

export default CreditCardForm;
